.bookListCentre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bookListMobile {
    border: 1px solid #F4F6F8;
    width: 88vw;
    border-radius: 14px;
    padding-left: 2vw;
    margin-top: 1vh;
}