.giftCardImageOuterContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.giftCardImageContainer {
    background-color: #D51325;
    width: 19vw;
    padding-top: 2vh;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.giftCardImageTextTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 17vw;
}

.giftCardImageText{
    width: 17vw;
    height: 10vh;
    padding-left: 1vw;
}

.giftCardImageLogoContainerCentre {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.giftCardImageLogoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 17vw;
    padding-bottom: 1vh;
}

.giftCardImageLogo {
    width: 3vw;
}

.smallGiftCardImageContainer {
    background-color: #D51325;
    width: 60vw;
    padding-top: 2vh;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
}

.smallGiftCardImageTextTitle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 55vw;   
}

.smallGiftCardImageText {
    width: 55vw;
    height: 10vh;
    padding-left: 1vw;
}

.smallGiftCardImageLogoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 55vw;
    padding-bottom: 1vh;
}

.smallGiftCardImageLogo {
    width: 10vw;
}

.mobileGiftCardImageContainer {
    background-color: #D51325;
    width: 85vw;
    padding-top: 2vh;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobileGiftCardImageTextTitle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 82vw;
}

.mobileGiftCardImageText {
    width: 82vw;
    height: 10vh;
}

.mobileGiftCardImageLogoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 82vw;
    padding-bottom: 1vh;
}

.mobileGiftCardImageLogo {
    width: 15vw;
}