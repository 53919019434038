.internalSiteSkeleton {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5vh
}

.internalSiteSkeletonWidth {
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.internalSiteSkeletonTitleContainerSelected {
    background-color: #F4F6F8;
    border-radius: 15px 15px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.5vw 2vw;
}

.internalSiteSkeletonTitleContainer {
    border-radius: 15px 15px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.5vw 2vw;
}

.internalSiteSkeletonHover:hover {
    opacity: 0.7;
}