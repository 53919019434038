.internalSiteBodyOuterContainer {
    width: 90vw;
    background-color: #F4F6F8;
    border-radius: 0px 0px 15px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 5vh;
}

.internalSiteElementContainer {
    width: 85vw;
    background-color: #FFFFFF;
    margin-top: 5vh;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.internalSiteElementText {
    width: 80vw;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 5vh 2vw;
    margin: 0 auto;
}

.internalSiteElementText > div:last-child {
    grid-column: span 1;
}

.arrowForward {
    width: 1.5vw;
}

.internalSiteButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.dropdownIcon {
    width: 2vw;
    cursor: pointer;
}

.dropdownIcon:hover {
    opacity: 0.7;
}

.archiveIcon {
    height: 2vh;
}

.deleteIcon {
    height: 2vh;
}

.internalSiteActionButton:hover {
    opacity: 0.7;
}

.internalPublicSectorButton:hover {
    opacity: 0.7;
}