.orderConfirmedCentre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
}

.orderConfirmedWidth {
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.orderConfirmedMobileWidth {
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.animalImageMobile {
    width: 20vw;
}

.animalImage {
    width: 5vw;
}