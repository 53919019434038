.payOuterContainer {
    padding-top: 5vh;
}

.payRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
}

.payOrderType {
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.payMain {
    width: 60vw;
    display: flex;
    flex-direction: column;
}

.payMainWidth {
    width: 25vw;
}

.payBookListItem {
    border: 1px solid #F4F6F8;
    width: 25vw;
    border-radius: 14px;
    padding-left: 1vw;
    margin-top: 1vh;
}

.payCentre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 1vw;
}

.payPricing {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 23vw;
}

.payPricingCentre {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 23vw;
    padding-top: 5vh;
}

.paymentContainer {
    width: 20vw;
}

.paymentContainerMobile {
    width: 90vw;
}

/* mobile */
.payColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobilePayWidth {
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.payMobileCentre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobilePayBookListItem {
    border: 1px solid #F4F6F8;
    width: 90vw;
    border-radius: 14px;
    padding-left: 1vw;
    margin-top: 1vh;
}

.mobilePayPricing {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80vw;
}

.mobilePayPricingCentre {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 23vw;
    padding-top: 5vh;
}

.animalPay {
    width: 7vw;
}

.mobileAnimalPay {
    width: 25vw;
}