.deliveryMobileOuterContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.deliveryMobileRadioContainer {
    width: 90vw; 
    border-radius: 30px;
    background-color: #FDFDFD;
    border: 2px solid #F4F6F8;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vh;
    height: 10vh;
}

.deliveryMobileSelectedRadioContainer {
    width: 90vw; 
    border-radius: 30px;
    background-color: #FDFDFD;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vh;
    height: 10vh;
    border: 2px solid #D51325;
}