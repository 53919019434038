.disableScroll {
  height: 100vh;
  overflow-y: scroll;
}

.internalSiteSubHeaderOuterContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin-top: 5vh;
}

.internalSiteSubHeaderWidth {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 95vw;
}

.internalSiteSubHeaderInnerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 35vw;   
}

.internalSiteSubHeaderInnerContainerSmall {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 45vw;   
}


.internalSiteSubHeaderText {
    transition: all ease-in-out 0.1s;
    position: relative;
    margin: 0 auto;
    padding: 0;
    color: #6D7286;
    line-height: 0.5em;
  }

  .internalSiteSubHeaderText::after {
    content: "";
    position: absolute;
    bottom: -10px;
    width: 0px;
    height: 2px;
    margin: 1px 0 0;
    transition: all ease-in-out 0.1s;
    transition-duration: 0.75s;
    opacity: 0;
    background-color: #6D7286;
    left: 0;
  }

  .internalSiteSubHeaderText:hover {
    cursor: pointer;
  }

  .internalSiteSubHeaderText:hover::after {
    width: 100%;
    opacity: 1;
  }

  .internalSiteSubHeaderSelectedText {
    position: relative;
    margin: 0 auto;
    padding: 0;
    color: #6D7286;
    line-height: 0.5em;
}

  .internalSiteSubHeaderSelectedText::after {
    content: "";
    position: absolute;
    bottom: -10px;
    width: 100%;
    height: 2px;
    margin: 1px 0 0;
    opacity: 1;
    background-color: #BC2239;
    left: 0;
}