.cartaDocenteCentre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5vh;
}

.cartaDocenteImage {
    width: 20vw;
}

.cartaDocenteImageMobile {
    width: 50vw;
}

.cartaDocenteDescription {
    width: 30vw;
}

.cartaDocenteDescriptionMobile {
    width: 80vw;
}