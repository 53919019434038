.giftCardOuterContainer {
    width: 100vw;
}

.giftCardInfoOuterContainer {
    padding-top: 50vh;
}

.giftCardInfoInnerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
}

.subheader {
    padding-left: 1vw;
}

.dateSubheader {
    padding-left: 10vw;
}

.priceSubheader {
    padding-left: 3vw;
}

.giftCardList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 2vw;
    gap: 2vh 2vw;
    width: 40vw;
    margin: 0 auto;
}

.radioContainer {
    width: 19vw; 
    border-radius: 30px;
    background-color: #FDFDFD;
    border: 2px solid #F4F6F8;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.selectedRadioContainer {
    width: 19vw; 
    border-radius: 30px;
    background-color: #FDFDFD;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #D51325;
}

.radioTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.giftCardCentre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reactCalendarStyle {
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.15);
    border: 0 !important;
}

.smallReactCalendarStyle {
    width: 90vw !important;
}

.mobileGiftCardInfoOuterContainer {
    padding-top: 22em;
}

.mobileDateSubheader {
    padding-top: 3em;
}

.mobileReactCalendarStyle {
    width: 90vw !important;
}

.mobilePriceSubheader {
    padding-top: 5em;
}

.mobileGiftCardList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 2vw;
    gap: 2vh 2vw;
    width: 90vw;
    margin: 0 auto;
}

.mobileRadioContainer {
    width: 43vw; 
    border-radius: 30px;
    background-color: #FDFDFD;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #F4F6F8;
}

.mobileSelectedRadioContainer {
    width: 43vw; 
    border-radius: 30px;
    background-color: #FDFDFD;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #D51325;
}

.paymentContainerGift {
    width: 40vw;
}