.bookIllustrationOuterMostContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50vw;
}

.bookIllustrationContainer {
    width: 43vw;
    height: 50vh;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
    padding-top: 5vh;
    padding-bottom: 10vh;
}

.bookIllustration {
    width: 25vw;
}

.smallBookIllustration {
    height: 50vh;
}