.voucherPaymentCentre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2vh;
}

.voucherPaymentRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.voucherCodeCircle:hover {
    opacity: 0.7;
    cursor: pointer;
}