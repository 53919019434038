.bookListContainer {
    width: 43vw;
    height: 63vh;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
    padding-top: 2vh;
}

.bookListCentre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bookListElementsCentreContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2vw;
    flex: 1;
    overflow-y: auto;
}

.bookListElementsCentre {
    height: 20vh;
    overflow-y: scroll;
    margin: 0 auto;
}

.bookListItem {
    border: 1px solid #F4F6F8;
    width: 35vw;
    border-radius: 14px;
    padding-left: 2vw;
    margin-top: 1vh;
}