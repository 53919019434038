.landingComponentMobileOuterContainer {
    background-color: #F4F6F8;
    padding-top: 10vh;
    width: 100vw;
}

.landingComponentMobileWidth {
    width: 95vw;
    background-color: #FFFFFF;
    padding-bottom: 5vh;
    margin: 0 auto;
}