.internalSiteGiftCardRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.internalSiteNewGiftCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20vw;
}

.internalSiteGiftCardOuterContainer{
    background-color: #FFFFFF;
    border-radius: 15px;
}

.internalSiteGiftCardGrid {
    background-color: #FFFFFF;
    border-radius: 15px;
    width: 70vw;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0vh 3vw;
    margin: 0 auto;
}

.internalSiteGiftCardElement {
    background-color: #F4F6F8;
    border-radius: 15px;
    width: 70vw;
    padding: 1vw;
}

.internalSiteGiftCardElementGrid {
    background-color: #FFFFFF;
    border-radius: 15px;
    width: 67vw;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0vh 3vw;
    margin: 0 auto;
    height: 8vh;
    padding-left: 1vw;
    padding-right: 1vw;
}

.internalSiteGiftCardElementContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 5vw;
    word-break: break-all;
}

.internalSiteGiftCardElementContainerRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}