.header {
    width: 100vw;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.headerRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 95vw;
}

.logoMondadori {
    width: 15vw;
    cursor: pointer;
}

.headerMobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    background-color: #FFFFFF;
}

.logoMondadoriMobile {
    width: 60vw;
    cursor: pointer;
}

.menu-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15vw;
    height: 8vh;
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.menu-btn__burger {
    width: 10vw;
    height: 0.5vh;
    background: #242529;
    border-radius: 5px;
    transition: all .5s ease-in-out;
    z-index: 100;
    margin: 0 auto;
  }
  .menu-btn__burger::before,
  .menu-btn__burger::after {
    content: '';
    position: absolute;
    width: 10vw;
    height: 0.5vh;
    background: #242529;
    border-radius: 5px;
    transition: all .5s ease-in-out;
    margin: 0 auto;
  }
  .menu-btn__burger::before {
    transform: translateY(-13px);
  }
  .menu-btn__burger::after {
    transform: translateY(13px);
  }

  .menu-btn.open .menu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }
  .menu-btn.open .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
  }
  .menu-btn.open .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }

.navModal {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: translateY(-100px);
    transition: all 500ms ease-in-out;
    background-color: #FFFFFF;
    width: 100vw;
}

.navModal.navModalActive {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    opacity: 1;
    transform: translateY(0px);
    background-color: #FFFFFF;
    width: 100vw;
    padding-top: 100vh;
    padding-bottom: 70vh;
}

.internalHeaderMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
}