.landingComponentOuterContainer {
    background-color: #F4F6F8;
    padding-top: 10vh;
}

.bookButtonsCentre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bookButtons {
    width: 80vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}