.publicSectorPayment {
    width: 20vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.publicSectorButton {
    width: 7.5vw;
}

.publicSectorButton:hover {
    opacity: 0.7;
    cursor: pointer;
}

.mobilePublicSectorPayment {
    width: 80vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.mobilePublicSectorButton {
    width: 30vw;
}