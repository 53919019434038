.bookPageOuterMostContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 50vw;
}

.bookPageContainer {
    width: 43vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5vh;
    background-color: #FFFFFF;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
    padding-bottom: 10vh;
    height: 50vh;
}

.centre {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 30vh
}

.chooseBookInputRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}