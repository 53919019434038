.footerOuterContainer {
    background-color: rgb(31, 31, 31);
    padding-top: 5vh;
    padding-bottom: 10vh;
    margin-top: 30vh;
    width: 100vw;
}

.footerLogoContainer {
    padding-left: 7.5vw;
}

.footerLogo {
    width: 10vw;
}

.footerGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5vh 9vw;
    width: 60vw;
    margin: 0 auto;
}

.footerFlex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.socialMedia {
    width: 3vw;
    cursor: pointer;
}

.socialMedia:hover {
    opacity: 0.7;
}

.footerBottom {
    width: 80vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

/* mobile */
.footerMobileOuterContainer {
    background-color: rgb(31, 31, 31);
    padding-top: 5vh;
    padding-bottom: 10vh;
    margin-top: 30vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footerMobileWidth {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50vw;
}

.footerLogoMobileContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50vw;
}

.footerLogoMobile {
    width: 30vw;
}

.socialMediaMobileContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 50vw;
    padding-top: 5vh;
}

.socialMediaMobile {
    width: 10vw;
}

.mobileFooterCompanyTag {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80vw;
    padding-top: 10vh;
}