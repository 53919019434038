.modal {
    width: 30vw;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 5vh;
    padding-top: 2vh;
}

.exitContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 27vw;
}

.exitSvg {
    cursor: pointer;
}

.modalWidth {
    width: 25vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}