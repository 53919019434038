.internalSiteBooksContainer {
    width: 75vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
}

.internalSiteBooksHeader {
    background-color: #F4F6F8;
    border-radius: 30px;
    width: 72vw;
    padding: 0vw 2vw;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0vh 2vw;
    margin: 0 auto;
}

.internalSiteBookGrid {
    width: 72vw;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0vh 2vw;
    margin: 0 auto;
    padding: 0vw 2vw;
}

.internalSiteBookTitleRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.internalSiteBookNumber {
    margin-right: 1vw;
}

.internalSiteTickHover:hover {
    opacity: 0.7;
}

.circleIconContainer {
    padding-top: 2%;
    padding-left: 15%;
}

/* .internalSiteBookGrid > div:first-child {
    background-color: red;
}

.internalSiteBookGrid > div:nth-child(2) {
    background-color: green;
}

.internalSiteBookGrid > div:nth-child(3) {
    background-color: blue;
} */

/* .internalSiteBookGrid > div:nth-child(4) {
    background-color: yellow;
} */